.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* @media only screen and (max-width: 576px) {
  body {
    display: none;
  }
} */

/* 
@media only screen and (max-width: 576px) {
}

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1400px) {
} */

/* Home */
.home-page .landing-page-1 {
  height: 800px;
}

.home-page .title-home-page {
  margin-top: 250px;
}

.home-page .rectangle {
  display: inline-block;
  width: 25px;
  height: 100px;
  background: #efc81a;
}

.home-page .swiper {
  width: 100%;
  height: 100%;
}

.home-page .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  border-radius: 8px;
}

.home-page .swiper-slide img {
  /* display: block; */
  /* width: 100%; */
  height: 400px;
  object-fit: fill;
  border-radius: 8px;

  border-radius: 8px;
  box-shadow: 1px 1px 5px grey;
}

/* .home-page .swiper-text {
  margin-top: 100px;
} */

@media only screen and (max-width: 576px) {
  .home-page .imageswipper1 {
    width: 150px;
    height: 150px !important;
    object-fit: cover !important;
  }
  .home-page .img-swiper-bottom {
    width: 150px !important;
    height: 150px !important;
    object-fit: cover !important;
  }
}

@media only screen and (min-width: 576px) {
  .home-page .imageswipper1 {
    width: 500px !important;
    height: 500px !important;
    object-fit: cover !important;
  }
  .home-page .img-swiper-bottom {
    width: 145px !important;
    height: 145px !important;
    object-fit: cover !important;
  }
}

@media only screen and (min-width: 768px) {
  .home-page .imageswipper1 {
    width: 200px !important;
    height: 200px !important;
    object-fit: cover !important;
  }
  .home-page .img-swiper-bottom {
    width: 200px !important;
    height: 200px !important;
    object-fit: cover !important;
  }
}

@media only screen and (min-width: 992px) {
  .home-page .imageswipper1 {
    width: 280px !important;
    height: 280px !important;
    object-fit: cover !important;
  }
  .home-page .img-swiper-bottom {
    width: 280px !important;
    height: 280px !important;
    object-fit: cover !important;
  }
}

@media only screen and (min-width: 1200px) {
  .home-page .imageswipper1 {
    width: 340px !important;
    height: 340px !important;
    object-fit: cover !important;
  }
  .home-page .img-swiper-bottom {
    width: 150px !important;
    height: 150px !important;
    object-fit: cover !important;
  }
}

@media only screen and (min-width: 1400px) {
  .home-page .imageswipper1 {
    width: 400px !important;
    height: 400px !important;
    object-fit: cover !important;
  }
  .home-page .img-swiper-bottom {
    width: 190px !important;
    height: 190px !important;
    object-fit: cover !important;
  }
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: rgba(247, 197, 60, 0.75) !important;
  opacity: 1;
}

/* sign-up */
.sign-up-page .bg-picture {
  width: 100%;
  height: 50%;
  /* max-height: 90%; */
  /* object-fit: fill; */
}

.sign-up-page .icon-picture {
  margin-top: 324px;
  margin-left: -416px;
  position: absolute;
}

.sign-up-page .form-sign-up {
  margin-top: 125px;
  /* padding-left: 50px;
  padding-right: 50px; */
}

/* sign-in */
.sign-in-page .bg-picture {
  width: 100%;
  /* max-height: 90%; */
  object-fit: cover;
}

.sign-in-page .icon-picture {
  margin-top: 324px;
  margin-left: -416px;
  position: absolute;
}

.sign-in-page .form-sign-in {
  margin-top: 180px !important;
  /* padding-left: 50px;
  padding-right: 50px; */
}

/*  Page not Found */
.page-not-found {
  margin-top: 200px;
  margin-bottom: 200px;
}

@media only screen and (min-width: 576px) {
  .page-not-found .text-not-found {
    /* margin-top: 500px; */
    color: #efc81a !important;
    font-size: 30px !important ;
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) {
  .page-not-found .text-not-found {
    /* margin-top: 500px; */
    color: #efc81a !important;
    font-size: 33px !important ;
    font-weight: bold;
  }
}

@media only screen and (min-width: 992px) {
  .page-not-found .text-not-found {
    /* margin-top: 500px; */
    color: #efc81a !important;
    font-size: 40px !important ;
    font-weight: bold;
  }
}

@media only screen and (min-width: 1200px) {
  .page-not-found .text-not-found {
    /* margin-top: 500px; */
    color: #efc81a !important;
    font-size: 40px !important ;
    font-weight: bold;
  }
}

@media only screen and (min-width: 1400px) {
  .page-not-found .text-not-found {
    /* margin-top: 500px; */
    color: #efc81a !important;
    font-size: 45px !important ;
    font-weight: bold;
  }
}

/* footer */
.footer-global {
  height: 245px;
  background: #efc81a;
}

/* Profile */
.utama {
  margin-bottom: 150px;
}
.picture-profile {
  height: 172px;
  width: 172px;
  object-fit: cover;
  border: 1px solid rgba(255, 196, 0, 0.911);
}

.icon-edit {
  width: 18px;
  height: 18px;
}

.nav-link {
  color: grey !important;
}
.nav-link.active {
  color: black !important;
}

footer .bottom {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer .bottom ul {
  display: flex;
  list-style: none;
}

footer .bottom ul li {
  margin: 0 10px;
}

.footer {
  background-color: #61dafb;
}

footer .bottom ul li a {
  color: black;
  font-size: 0.8rem;
  text-decoration: none;
}

/* Navigation Bar */
.navbar-page .btn-search {
  color: gray;
  border: 0;
  display: inline-block;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 10px;
  width: 50px;
  /* margin-right: 20px; */
}

.navbar-page .btn-register {
  height: 38px !important;
  width: 100px !important;
}

.navbar-page .btn-login {
  height: 38px !important;
  width: 70px !important;
}

.navbar-page .btn-sign-up {
  white-space: nowrap;
  text-align: center;
}

.ShadowBox {
  box-shadow: 1px 1px 5px grey;
}

/* .navbar-page .form-control[type=search]:focus,
.navbar-page .form-control[type=search]:hover
 {
  box-shadow: 0px 0px 5px 2px rgba(252, 90, 90, 0.75) !important;
} */

.form-input:hover,
.form-input:focus {
  box-shadow: 0px 0px 5px 2px rgba(255, 199, 78, 0.75) !important;
}

.form-control:focus {
  border-color: var(--bs-warning) !important;
  /* box-shadow: 0px 0px 5px 2px rgba(252, 90, 90, 0.75) !important; */
  box-shadow: none !important;
}

.navbar-page .form-control[type="search"]:focus,
.offcanvas.offcanvas-bottom {
  width: 100% !important;
  height: 100% !important;
}

.ico {
  height: 24px;
  width: 24px;
}

.photoSide {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid rgba(247, 197, 60, 0.75);
}

.btn-close-offcanvas {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E")
    50%/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.pictureThumbnails {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid rgba(247, 197, 60, 0.75);
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-menu {
  --bs-dropdown-color: #212529 !important;
  --bs-dropdown-bg: #fff !important;
  --bs-dropdown-link-color: grey !important;
  --bs-dropdown-link-hover-color: #1e2125 !important;
  --bs-dropdown-link-hover-bg: #e9ecef !important;
  --bs-dropdown-link-active-color: #fff !important;
  --bs-dropdown-link-active-bg: grey !important;
}

.logo-toggle-navbar {
  height: 45px;
}

.form-check-input:checked {
  background-color: rgba(255, 196, 0, 0.911) !important;
  border: 0;
}
.form-check-input:focus,
.label::after,
label.form-check-label:focus,
.form-check-input::after,
.form-check-input:not(:disabled):not(.disabled):active:focus {
  color: black;
  outline: 0;
  border: 0;
  box-shadow: 0 0 0 0.1rem rgba(255, 196, 0, 0.911) !important;
}




/* Details Recipes  */
.recipes-details-description{
  white-space: pre-line !important;
}

.recipes-details-comment{
  white-space: pre-line !important;
}

.text-area-comment {
  resize: none !important;
}

.icon-likes {
  height: 25px;
  width: 25px;
}

/* .btn-likes:active{
  
  color: #f8f9fa !important;

} */

/* .btn-likes:active{
  color: #f8f9fa !important;
  background-color: #f8f9fa !important;
} */



.detail-resep-page .btn.btn-light {
  color: #fff !important;
  background-color: #fff !important;;
  border-color: #fff !important;;
}
.detail-resep-page .btn.btn-light.focus,
.detail-resep-page .btn.btn-light:focus {
  color: #fff !important;;
  background-color: #fff !important;;
  border-color: #fff!important;;
  outline: none !important;;
  box-shadow: none !important;;
}
.detail-resep-page  .btn.btn-light:hover {
  color: #fff !important;;
  background-color: #fff !important;;
  border-color: #fff !important;;
  outline: none!important;;
  box-shadow: none !important;;
}
.detail-resep-page .btn.btn-light.active,
.detail-resep-page .btn.btn-light:active {
  color: #fff!important;;
  background-color: #fff!important;;
  border-color: #fff!important;;
  outline: none!important;;
}
.detail-resep-page .btn.btn-light.active.focus,
.detail-resep-page .btn.btn-light.active:focus,
.detail-resep-page .btn.btn-light.active:hover,
.detail-resep-page .btn.btn-light:active.focus,
.detail-resep-page .btn.btn-light:active:focus,
.detail-resep-page .btn.btn-light:active:hover {
  color: #fff !important;;
  background-color: #fff !important;;
  border-color: #fff !important;;
  outline: none!important;;
  box-shadow: none!important;;
}



@media only screen and (max-width: 576px) {
  .detail-resep-page .img-promotion {
    height: 200px;
    width: 200px;
  }
}

@media only screen and (min-width: 576px) {
  .detail-resep-page .img-promotion {
    height: 300px;
    width: 300px;
  }
}

@media only screen and (min-width: 768px) {
  .detail-resep-page .img-promotion {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (min-width: 992px) {
  .detail-resep-page .img-promotion {
    height: 400px;
    width: 400px;
  }
}

@media only screen and (min-width: 1200px) {
  .detail-resep-page .img-promotion {
    height: 500px;
    width: 500px;
  }
}

@media only screen and (min-width: 1400px) {
  .detail-resep-page .img-promotion {
    height: 500px;
    width: 500px;
  }
}

/* Search Recipes */

/* @media only screen and (max-width: 576px) {
  .out-img-recipes {
    width: 100%;
    height: 100%;

    margin-top: 12px;
    margin-bottom: 12px;
  }

  .img-recipes {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .title-recipes {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-description-search {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
} */

@media only screen and (min-width: 576px) {
  .out-img-recipes {
    width: 225px;
    height: 225px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .img-recipes {
    max-width: 225px;
    max-height: 225px;
    object-fit: cover;
  }
  .title-recipes {
    white-space: nowrap;
    width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-description-search {
    white-space: nowrap;
    width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 768px) {
  .out-img-recipes {
    width: 300px;
    height: 300px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .img-recipes {
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;
  }
  .title-recipes {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-description-search {
    white-space: nowrap;
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 992px) {
  .out-img-recipes {
    width: 275px;
    height: 275px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .img-recipes {
    max-width: 275px;
    max-height: 275px;
    object-fit: cover;
  }
  .title-recipes {
    white-space: nowrap;
    width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-description-search {
    white-space: nowrap;
    width: 275px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 1200px) {
  .out-img-recipes {
    width: 325px;
    height: 325px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .img-recipes {
    max-width: 325px;
    max-height: 325px;
    object-fit: cover;
  }
  .title-recipes {
    white-space: nowrap;
    width: 325px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-description-search {
    white-space: nowrap;
    width: 325px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 1400px) {
  .out-img-recipes {
    width: 375px;
    height: 375px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .img-recipes {
    max-width: 375px;
    max-height: 375px;
    object-fit: cover;
  }
  .title-recipes {
    white-space: nowrap;
    width: 375px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .text-description-search {
    white-space: nowrap;
    width: 375px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Pagination */
.pagination .active .page-link {
  color: var(--bs-white) !important;
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.pagination .page-link {
  color: var(--bs-warning) !important;
  background-color: var(--bs-white);
  border-color: var(--bs-warning);
}

.pagination .page-link:hover {
  color: var(--bs-white) !important;
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.pagination .page-link:hover:focus {
  /* color: rgba(0, 0, 0, .85); */
  /* background-color: transparent; */
  box-shadow: 0px 0px 5px 2px rgba(247, 197, 60, 0.75) !important;
}

/*My Profile*/
.img-thumbnails {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.text-description {
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 576px) {
  .title-recipes-table {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-recipes-table {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-recipes-table {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 576px) {
  .title-recipes-table {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-recipes-table {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 768px) {
  .title-recipes-table {
    white-space: nowrap;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-recipes-table {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 992px) {
  .title-recipes-table {
    white-space: nowrap;
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-recipes-table {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 1200px) {
  .title-recipes-table {
    white-space: nowrap;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-recipes-table {
    white-space: nowrap;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 1400px) {
  .title-recipes-table {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description-recipes-table {
    white-space: nowrap;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



.ico-search{
  margin-right: 10px;
}





/* my recipes */
.my-recipes-table{
  border-radius: 10px !important;
}



.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
